.dashboard-table td.invoice-dashboard-ellipsis-wrapper {
  padding-right: 0;
}

.dashboard-table th.invoice-dashboard-customer {
  width: 36%;
}

.dashboard-table th.invoice-dashboard-date-due {
  width: 16%;
}

.dashboard-table th.invoice-dashboard-amount {
  width: 18%;
}

.dashboard-table th.invoice-dashboard-ellipsis {
  width: 8%;
}

.dashboard-table td.invoice-dashboard-amount {
  font-weight: bold;
}

.dashboard-table td.invoice-dashboard-date-paid {
  color: grey;
}

.dashboard-table td.invoice-dashboard-date-paid.editing-another-date-paid span {
  cursor: default;
}

.dashboard-table td.invoice-dashboard-date-paid:not(.editing-another-date-paid) span,
.dashboard-table td.invoice-dashboard-date-paid .invoice-dashboard-unset-date-paid {
  cursor: pointer;
}

.dashboard-table td.invoice-dashboard-date-paid:not(.editing-another-date-paid) span:hover,
.dashboard-table td.invoice-dashboard-date-paid .invoice-dashboard-unset-date-paid:hover {
  color: rgb(100, 100, 100);
  font-weight: bold;
}

.dashboard-table td.invoice-dashboard-date-paid input {
  cursor: text;
  font-size: small;
  padding: 4px;
  margin-bottom: -10px;
  margin-top: -10px;
  width: 80%;
  border-width: 1px;
  text-align: center;
}

.dashboard-table td.invoice-dashboard-date-paid.paid span:not(.invoice-dashboard-unset-date-paid) {
  color: #0ADA5D;
  font-weight: bold;
}

.dashboard-table td.invoice-dashboard-date-paid .invoice-dashboard-unset-date-paid {
  margin-left: 7px;
}

.invoice-dashboard-ellipsis-wrapper {
  text-align: center !important;
  font-weight: bold;
  cursor: pointer;
}

.invoice-dashboard-ellipsis {
  transform: rotate(-90deg);
  color: grey;
}

.invoice-dashboard-ellipsis-wrapper:hover .invoice-dashboard-ellipsis,
.invoice-dashboard-ellipsis.active {
  color: black;
  font-weight: bold;
}

.invoice-dashboard-ellipsis-wrapper .dropdown {
  display: inline;
  position: relative;
  top: .8em;
  z-index: 100;
  text-align: left;
  font-weight: normal;
}

#invoice-dashboard-ellipsis-dropdown-wrapper {
  float: right;
  direction: rtl;
}

.invoice-dashboard-row-due-prefix {
  display: none;
}

@media screen and (max-width: 768px) {
  .dashboard-with-table {
    padding-top: 70px;
  }

  .dashboard-with-table-outer-container {
    padding-top: 20px;
  }

  .dashboard-with-table>div {
    width: 100%;
  }

  .dashboard-with-table-total {
    padding-left: 1em;
    box-sizing: border-box;
  }

  .dashboard-with-table .container {
    border: 0;
    border-radius: 0;
    box-shadow: unset;
    padding: 0;
    background: unset;
    background-color: #FFFCF2;
    min-height: unset;
  }

  .dashboard-with-table thead {
    display: none;
  }

  .invoice-dashboard-row-due-prefix {
    display: initial;
  }

  #invoice-dashboard tbody td {
    padding-bottom: .8rem;
  }

  #invoice-dashboard tbody tr {
    display: grid;
    grid-template-areas: 
      'top-left         top-right full-right'
      'bottom-left   bottom-right full-right'
    ;
    grid-template-columns: 50% 40% 10%;
  }

  .invoice-dashboard-row-customer {
    grid-area: top-left;
    padding-bottom: 0 !important;
  }

  .invoice-dashboard-row-date-due {
    grid-area: bottom-left;
    padding-left: 1rem;
    font-size: .8em;
    color: grey;
  }

  .invoice-dashboard-date-paid {
    grid-area: bottom-right;
    font-size: .8em;
    color: grey;
    text-align: right !important;
  }

  .invoice-dashboard-amount {
    grid-area: top-right;
    padding-bottom: 0 !important;
  }

  .invoice-dashboard-ellipsis-wrapper {
    grid-area: full-right;
    margin-left: -8px;
    margin-top: 12px;
  }

  .invoice-dashboard-date-paid input {
    float: right;
    margin-top: -6px !important;
    width: 85% !important;
  }

  .invoice-dashboard-unset-date-paid {
    display: block;
    width: 15%;
    text-align: center;
    margin-left: 0 !important;
  }
}