.topnav-container {
  width: 100%;
  display: flex;
  background-color: #32363c;
  box-shadow: 0px 4px 4px #00000040;
}

ul.topnav {
  width: 100%;
  margin: auto;
  list-style-type: none;
  top: 0;
  padding: 0;  
}

ul.topnav li {
  float: left;
}

ul.topnav li.right {
  float: right;
}

.desktop-topnav-item {
  height: 55px;
}

.topnav-item {
  display: block;
  color: #c6c386;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  border-top: 2px solid transparent;
  border-bottom: 5px solid #32363c;
}

.desktop-topnav-item a {
  box-sizing: border-box;
  height: 100%;
}

.topnav-item:hover:not(.active) {
  color: #87c6c3;
  background-color: #25282d;
  border-top: 2px solid #25282d;
  border-bottom: 5px solid #25282d;
}

.topnav-item.active {
  color: #C3EEE7;
  border-bottom: 5px solid #C3EEE7;
}

.mobile-nav-bar-items {
  display: none;
}

.dropbtn {
  line-height: 50px;
  color: #c6c386;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: x-large;
  cursor: pointer;
  border-bottom: 5px solid #32363c;
}

.dropdown {
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #FFFCF2;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  width: fit-content;
  min-width: 10rem;
}

/* prevents hover on touch devices becaues it lingers after click */
@media (hover) {
  .dropdown:hover .dropbtn {
    color: #87c6c3;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }
}

.dropdown.active>.dropbtn {
  color: #87c6c3;
}

.dropdown.active>.dropdown-content {
  display: block;
}

.dropdown-item {
  cursor: pointer;
  padding: .6em 1em;
  vertical-align: middle;
}

.dropdown-item:first-child {
  margin-top: .5em;
}

.dropdown-item:last-child {
  margin-bottom: .5em;
}

.dropdown-divider {
  cursor: unset;
  border: 1px solid #e1e1e1;
  width: 90%;
  margin: auto;
  margin-top: .5em;
  margin-bottom: .5em;
}

.dropdown-item:hover {
  background-color: #d8fff89e;
}

.dropdown-item a {
  text-decoration: none;
}

.dropdown-item a.active {
  color: #87c6c3;
}

ul.topnav li button.button-inverted {
  padding: 0;
  box-shadow: none;
}

#new-invoice-button-wrapper .button-inverted {
  font-size: medium;
  margin-top: 10px;
}

@media (hover) {
  ul.topnav li button.button-inverted:hover,
  #new-invoice-button-wrapper:hover .button-inverted {
    color: #87c6c3;
  }
}

#new-invoice-button-wrapper>.left-button-with-icon {
  width: 175px;
  vertical-align: top;
  padding-bottom: 1px;
}

.button-icon {
  padding-top: 1.5px;
  height: 85%;
  width: 70%;
}

.mobile-nav-bar {
  display: none;
}

@media screen and (max-width: 768px) {
  .desktop-topnav-item {
    display: none;
  }

  .mobile-nav-bar-items {
    display: block;
    width: fit-content;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    line-height: 50px;
  }

  .mobile-nav-bar-item {
    width: 70px;
    padding: 4px;
    color: #c6c386;
    border-color: #c6c386;
    background-color: transparent;
    border-radius: 0;
    border-left-width: 0;
    border-right-width: 0;
    cursor: pointer;
  }

  .mobile-nav-bar-item.active {
    color: #32363c;
    background-color: #c6c386;
  }

  @media (hover) {
    .mobile-nav-bar-item:hover:not(.active) {    
      color: #87c6c3;
      background-color: #25282d;
    }
  }
  
  .mobile-nav-bar-item:first-child {
    border-left-width: 1.5px;
    border-right-width: 1.5px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  
  .mobile-nav-bar-item:last-child {
    border-left-width: 1.5px;
    border-right-width: 1.5px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .mobile-nav-bar-item>svg {
    margin-bottom: -1px;
  }

  #new-invoice-button-wrapper>.left-button-with-icon {
    display: none;
  }

  #new-invoice-button-wrapper>.right-button-with-icon {
    border: 0;
    background: transparent;
  }

  #new-invoice-button-wrapper .button-icon {
    padding-top: 0;
  }

  .dropbtn {
    padding-left: 1rem;
    border: 0;
  }
}